<template>
	<AuthWrapper>
		<p class="auth-notice">
			Already have an account? <router-link to="/login">Sign In</router-link>
		</p>
		<div class="auth-contents">
			<a-form name="signup" layout="vertical">
				<sdHeading as="h3">
					Sign Up to <span class="color-secondary">SmartBusiness</span>
				</sdHeading>
				<a-form-item name="name" label="Name *">
					<a-input v-model:value="formData.name" placeholder="Full name" />
					<span class="error-messages" v-if="v$.formData.name.$error">
						{{ v$.formData.name.$errors[0].$message }}
					</span>
				</a-form-item>
				<a-form-item name="email" label="Email Address *">
					<a-input
						type="text"
						name="email"
						v-model:value="formData.email"
						placeholder="name@example.com"
					/>
					<span class="error-messages" v-if="v$.formData.email.$error">
						{{ v$.formData.email.$errors[0].$message }}
					</span>
				</a-form-item>
				<a-form-item name="password" label="Password *">
					<a-input-password
						type="password"
						name="password"
						v-model:value="formData.password"
						placeholder="Password"
					/>
					<span class="error-messages" v-if="v$.formData.password.$error">
						{{ v$.formData.password.$errors[0].$message }}
					</span>
				</a-form-item>
				<a-form-item name="password_confirmation" label="Confirm password *">
					<a-input-password
						type="password"
						name="password_confirmation"
						v-model:value="formData.password_confirmation"
						placeholder="Confirm password"
					/>
					<span
						class="error-messages"
						v-if="v$.formData.password_confirmation.$error"
					>
						{{ v$.formData.password_confirmation.$errors[0].$message }}
					</span>
				</a-form-item>
				<a-form-item name="phone_number" label="Phone number *">
					<a-input
						type="text"
						name="phone_number"
						v-model:value="formData.phone_number"
						placeholder="Phone number"
					/>
					<span class="error-messages" v-if="v$.formData.phone_number.$error">
						{{ v$.formData.phone_number.$errors[0].$message }}
					</span>
				</a-form-item>
				<div class="auth-form-action">
					<a-checkbox @change="onChangeTerms">
						Creating an account means you’re okay with our <router-link :to="{name: 'terms'}">Terms of Service</router-link> and
						<router-link :to="{name: 'terms'}">Privacy Policy</router-link>
						<br />
						<span class="error-messages">
							{{ terms_error }}
						</span>
					</a-checkbox>
				</div>
				<a-form-item>
					<sdButton
						class="btn-create"
						htmlType="submit"
						type="primary"
						size="large"
						@click="signUp"
					>
						{{ store.state.actions.isLoading ? "Loading..." : "Create account" }}
					</sdButton>
				</a-form-item>

				<a-form-item>
					<sdButton
						class="btn-create"
						htmlType="submit"
						type="danger"
						size="large"
						@click="signUpGoogle"
					>
						<img :src="require('@/static/img/google.png')" alt="" />
						<span>Sign up with Google</span>
					</sdButton>
				</a-form-item>
			</a-form>
		</div>
	</AuthWrapper>
</template>
<script>
import { AuthWrapper } from "./style";

import useVuelidate from "@vuelidate/core";
import {
	required,
	email,
	helpers,
	minLength,
	sameAs
} from "@vuelidate/validators";

import AuthService from "@/services/AuthService";

import store from "@/vuex/store";

import google from '../../utility/google';
import { parseCookie } from '../../utility/utility';

export default {
	name: "SignUp",

	components: { AuthWrapper },

	data() {
		return {
			v$: useVuelidate(),
			isLoading: false,
			formData: {
				name: "",
				email: "",
				password: "",
				password_confirmation: "",
				phone_number: "",
				rewardful: window.location.href.split("?via=").pop() !== window.location.href ? window.location.href.split("?via=").pop() : "",
				referral: window.location.href.split("?referral").pop() !== window.location.href ? window.location.href.split("?referral=").pop() : "",
			},
			terms: "",
			terms_error: "",
			store: store
		};
	},

	methods: {
		async signUp() {
			this.terms_error = "";
			this.$store.dispatch("actions/setLoading", true);
			this.v$.formData.$touch();
			
			if (!this.terms) {
				this.$store.dispatch("actions/setLoading", false);
				this.terms_error = "Please agree Terms and Conditions.";
				return;
			}

			if (this.v$.formData.$pending || this.v$.formData.$error) {
				this.$store.dispatch("actions/setLoading", false);
				return;
			}

			await AuthService.signUp(this.formData);

			await window.showAlert(
				"success",
				"Success",
				"Registration successfully completed!"
			);

			gr('track', 'conversion', { email: this.formData.email });

			this.$store.dispatch("actions/setLoading", false);

			await this.$router.push("/login");
		},

		onChangeTerms(e) {
			this.terms = e.target.checked;
		},

		signUpGoogle() {
			window.location.href = google.getGoogleUrl(`/?referral=${this.formData.referral}`);
		},

		handleGoogleAuthRedirect() {
			const cookies = parseCookie(document.cookie);

			if (cookies.token && cookies.user) {
				this.$store.dispatch("auth/setUserToken", cookies.token);

				this.$store.dispatch("auth/setUserDetails", JSON.parse(cookies.user));

				this.$store.dispatch("auth/setUserStep", JSON.parse(cookies.user).step);

				this.$router.push("/");
			}

			if (cookies.error_google) {
				window.showAlert('error', 'Error', cookies.error_google);
			}
		}
	},

	validations() {
		return {
			formData: {
				name: {
					required: helpers.withMessage("The field name is required", required)
				},

				email: {
					required: helpers.withMessage("The field email is required", required),

					email: helpers.withMessage("The field email is not valid", email)
				},

				password: {
					required: helpers.withMessage("The field password is required", required),
					minLength: helpers.withMessage(
						"The minimum number of characters is 8",
						minLength(8)
					)
				},

				password_confirmation: {
					required: helpers.withMessage(
						"The field confirm password is required",
						required
					),
					minLength: helpers.withMessage(
						"The minimum number of characters is 8",
						minLength(8)
					),
					sameAs: helpers.withMessage(
						"Passwords do not match",
						sameAs(this.formData.password)
					)
				},

				phone_number: {
					required: helpers.withMessage(
						"The field phone number is required",
						required
					)
				}
			}
		};
	}
};
</script>
